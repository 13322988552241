
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import JwtService from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { VueCookieNext } from "vue-cookie-next";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";
import { apiEndpoint } from "@/mixin/apiEndpoint";

export default defineComponent({
  mixins:[apiEndpoint],
  name: "switcher",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
    data() {
    return {
      api_url: "",
      institute_info_id: "",
      switch: {},
      entityInfos: [],
      load: false,
      loading: false,
    };
  },
  async created() {
    const router = useRouter();
    this.api_url=this.VUE_APP_API_URL;
    this.institute_info_id= VueCookieNext.getCookie("_institute_info_id");
    this.switch = this.$route.params.switch;
    // if (!this.switch) {
    //   console.log("vule dhuke gesre")
    //   router.push({ name: "sign-in" });
    // }

    if (this.institute_info_id == 'null') {
        router.push({ name: "dashboard" });

    }else{
        await this.getEntityInfos();
      if (this.entityInfos.length == 1) {
            router.push({ name: "dashboard" });
        }
    }
  },
    methods: {
        
    async getEntityInfos() {
        await ApiService.get("institute/detail/get_entity_institute_info/"+this.institute_info_id+"?active_status=1&unique_entity=1")
        .then((response) => {
            this.entityInfos = response.data.data;  
        })
        .catch(({ response }) => {
            console.log(response);
        });
    },
    userSwitcher(id){
        VueCookieNext.setCookie('_entity_id',id);
        this.$router.push("/dash");
    }

    },
 




});
